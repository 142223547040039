import React from 'react';

const oops = {
    'fontFamily': 'Lato',
    'fontWeight': 600,
    'fontSize': '2rem',
    'textAlign': 'center',
    'color': '#adadad'
};

export default class Footer extends React.Component {
    render(){
        return(
            <React.Fragment>
                <h3 style={{...oops, cursor: 'default'}}>Oops! Something went wrong.</h3>
            </React.Fragment>
        );
    }
}