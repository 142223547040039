import React from 'react';
import axios from 'axios';

export default class Home extends React.Component {

    constructor(props){
        super(props);
        
        this.state = {
            username: '',
            image: '',
            progress: false,
            success: false,
            clock: 10,
            error: ''
        }
    }

    progress = () => {
        const time = setInterval(() => {
            this.setState({
                clock: this.state.clock - 1
            });
            if(this.state.clock < 1){
                clearInterval(time);
            }else if(this.state.clock === 1){
                clearInterval(time);
            }
        }, 1000);
    }

    message = (error) => {
        this.setState({ 
            error: error,
            progress: false
        });
    }

    getPicture = (username) => {
        if(username !== '' && username !== undefined && username !== null){
            this.setState({ clock: 10, progress: true });
            this.progress();
            if(username.startsWith("https")){
                username = username.slice(22, username.indexOf("?"))
            }
            const url = `https://insta.codecraftdigital.cloud/api/instadploader/${username}`;
            axios.get(url)
            .then(data => {
                // console.log(data.data);
                if(data.data.Status == true){
                    if(data.data.Profile_Picture === 'http://insta.codecraftdigital.cloud/'){
                        this.message('*Username or url are not valid.');
                    }else{
                        this.setState({ image: data.data.Profile_Picture, success: true, progress: false });
                    }
                }else{
                    this.message('*Username or url are not valid.');
                }
            })
            .catch(err => {
                this.message('*Please check internet connection.');
            });
        }else{
            this.message('*Please check the username.');
        }
    }

    dpdownload = async () => {
        const url = this.state.image.replace('http', 'https');
        const blob = await axios.get(url, {
            headers: {
              'Content-Type': 'application/octet-stream',
            },
            responseType: 'blob',
        });
        const a = document.createElement('a');
        const href = window.URL.createObjectURL(blob.data);
        a.href = href;
        a.download = this.state.username;
        a.click();
    };

    render(){
        return(
            <React.Fragment>
                <div className="main">
                    <div className="box">
                        <form>
                            { this.state.success 
                                ? 
                                    <React.Fragment>
                                        <img src={this.state.image} alt="image" />
                                        <div className="button_container">
                                            <button className="btn" onClick={(e) => {
                                                e.preventDefault();
                                                this.dpdownload();
                                                this.setState({ clock: 10});
                                            }}>Download</button>
                                            <button className="btn" onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    username: '',
                                                    image: '',
                                                    progress: false,
                                                    success: false,
                                                    clock: 10,
                                                    error: ''
                                                });
                                            }}>Re-Search</button>
                                        </div>
                                    </React.Fragment>
                                : 
                                    <React.Fragment>
                                        { this.state.progress && <div className="progress">{this.state.clock}</div>}
                                        <input 
                                            type="text" 
                                            name="username" 
                                            id="username" 
                                            placeholder="Instagram Username or Url"
                                            value={this.state.username}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                this.setState({ username: e.target.value.toLowerCase() });
                                            }}
                                        />
                                        <small className="error">{this.state.error}</small>
                                        <button className="btn" onClick={(e) => {
                                            e.preventDefault();
                                            this.getPicture(this.state.username);
                                        }}>Search Profile</button>
                                    </React.Fragment>
                            }
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}