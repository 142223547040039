import React from 'react';
import Logo from '../Images/instadploader.svg';

export default class About extends React.Component {
    render(){
        return(
            <React.Fragment>
                <div className="main">
                    <div className="content_box">
                        <div className="intro">
                            <div className="logo">
                                <img src={Logo} alt="logo" />
                            </div>
                            <div className="heading">
                                <div className="name">Insta<span>DpLoader</span></div>
                                <div className="small">An Instagram DP downloader v2.3.0</div>
                            </div>
                        </div>
                        <div className="pera">
                            <p>
                                It's totally wrong to use someone's image in some wrong activity you can use it as a fun tool and also you view and download the image of your friends and loved ones with very high quality.
                            </p>
                        </div>
                        <div className="salute">
                            <p>Thank You!</p>
                        </div>
                        {/* Android Application */}
                        <div className="api">
                            <p className="api_about">You can also use our android application.</p>
                            <div className="copy">
                                <p>{'Android Application'}</p>
                                <a href="https://play.google.com/store/apps/details?id=ml.instadploader" style={{textDecoration: 'none', color: '#ef5350'}}><button>Go</button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}