import React from 'react';
import axios from 'axios';

export default class Contact extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            name: '',
            email: '',
            query: '',
            success: false,
            error: false,
            nameError: false,
            emailError: false,
            queryError: false
        }
    }

    errorChecking = () => {
        // check name
        if(this.state.name === '' || this.state.name === null || this.state.name === undefined){
            this.setState({ nameError: true });
            return false;
        }else{
            this.setState({ nameError: false });
        }
        // check email
        if(this.state.email === '' || this.state.email === null || this.state.email === undefined){
            this.setState({ emailError: true });
            return false;
        }else{
            this.setState({ emailError: false });
        }
        // check query
        if(this.state.query === '' || this.state.query === null || this.state.query === undefined){
            this.setState({ queryError: true });
            return false;
        }else{
            this.setState({ queryError: false });
        }
        return true;
    }

    submit = () => {
        if(this.errorChecking()){
            axios.post(`https://yourquerys.ml/api/send/10b2f99a-12b2-4873-ab6a-3228cc39a0c2`, {
                name: this.state.name,
                email: this.state.email,
                query: this.state.query
            }).then(data => {
                this.setState({ success: true });
                this.setState({ error: false });
            }).catch(err => {
                this.setState({ error: true });
                this.setState({ success: false });
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="box">
                        <form>
                            { this.state.success ? 
                                <React.Fragment>
                                    <h2 className="thanks">Thank You!</h2>
                                </React.Fragment>
                            :
                                this.state.error ?
                                    <React.Fragment>
                                        <h2 className="thanks">Some Error Occurred.</h2>
                                    </React.Fragment>
                                :
                                <React.Fragment>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="*John doe"
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({ name: e.target.value });
                                    }}
                                />
                                { this.state.nameError && <span className="error_chacking">*Please enter name.</span> }
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    style={{marginTop: '1rem'}}
                                    placeholder="*example@test.com"
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({ email: e.target.value });
                                    }}
                                />
                                { this.state.emailError && <span className="error_chacking">*Please enter email.</span> }
                                <textarea
                                    type="text"
                                    name="query"
                                    id="query"
                                    style={{'height': '30%', 'resize': 'none', marginTop: '1rem'}}
                                    placeholder="*It's an amazing idea."
                                    onChange={(e) => {
                                        e.preventDefault();
                                        this.setState({ query: e.target.value });
                                    }}
                                ></textarea>
                                { this.state.queryError && <span className="error_chacking">*Please enter query.</span> }
                                <button type="submit" className="btn" onClick={(e) => {
                                    e.preventDefault();
                                    this.submit();
                                }}>Submit Query</button>
                            </React.Fragment>}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}