import React from 'react';
import Logo from '../Images/instadploader.svg';
import { NavLink } from 'react-router-dom';

export default class Header extends React.Component {
    render() {
        return (
            <React.Fragment>
                <header>
                    <div className="logo">
                        <NavLink exact to="/">
                            <img draggable="false" src={Logo} alt="logo" />
                        </NavLink>
                    </div>
                    <div className="manu">
                        <div className="nav">
                            <ul>
                                <li>
                                    <NavLink exact to="/" activeClassName="active_nav">Home</NavLink>
                                </li>
                                <li>
                                    <NavLink exact to="/about" activeClassName="active_nav">About</NavLink>
                                </li>
                                <li>
                                    <NavLink exact to="/contact" activeClassName="active_nav">Contact</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}