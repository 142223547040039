import React from 'react';

export default class Footer extends React.Component {
    render(){
        return(
            <React.Fragment>
                <footer>
                    <h3>Made with <small className="love">❤</small> by <small className="author"><a href="https://suryapratap.netlify.app/">Suryapratap</a></small></h3>
                </footer>
            </React.Fragment>
        );
    }
}